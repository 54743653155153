const works = [
	{
		importance: 9,
		title: "AHNY",
		modalNum: "01",
		description: "Armenian Home of NY UI",
		imageURL: "images/portfolio/AHNYs.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-AHNY.jpg",
			description: "Features Implemented: ReactJS, HTML, CSS, MongoDB",
			categories: ["Web Development"],
			links: [
				{ url: "http://armenianhomeny.org/", title: "Link" },
				{ url: "https://github.com/anmedina-su/NY-Armenian-Home", title: "Github" },
			],
		},
	},
	{
		importance: 7,
		title: "PGA Tour Data Visualization",
		modalNum: "02",
		description: "Web Development &amp; Data Visualization",
		imageURL: "images/portfolio/golf.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-golf.jpg",
			description: "Features Implemented: Python, NodeJS, Reactjs, Javascript, MongoDB",
			categories: ["Data Analysis", "Data Visualization", "Web Development"],
			links: [
				{ url: "https://infallible-leakey-cdbeab.netlify.app/", title: "Link" },
				{ url: "https://github.com/anmedina-su/Golf-Data-Analysis", title: "Github" },
			],
		},
	},
	{
		importance: 4,
		title: "Django Social Media Site",
		modalNum: "03",
		description: "Web Development",
		imageURL: "images/portfolio/social-media.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-social-media.jpg",
			description: `Very simple Social Media site. Features Implemented: Python, Django, PostgreSQL`,
			categories: ["Python", "Web Development"],
			links: [
				{
					url: "https://github.com/anmedina-su/Python-Django-SimpleSocialMediaSite",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 5,
		title: "myBetJournal",
		modalNum: "04",
		description: "Android Programming",
		imageURL: "images/portfolio/horse-racing.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-horse-racing.jpg",
			description: `About myBetJournal: Spearheaded development of an Android mobile application that
                allows users to track and record sports bets, analyze betting performance, and
                receive updates on the latest trends in sports betting. Features Used: Firebase
                NoSQL Database, Data Visualization, Firebase Authentication, RecyclerView,
                Fragments, Kotlin, YouTube API`,
			categories: ["Android", "Kotlin"],
			links: [
				{
					url: "https://github.com/anmedina-su/myBetJournal",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 2,
		title: "Blackjack Project",
		modalNum: "05",
		description: "Java Programming",
		imageURL: "images/portfolio/blackjack.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-blackjack.jpg",
			description: `Blackjack in Java. Features Implemented: Java, OOP`,
			categories: ["Java", "OOP"],
			links: [
				{
					url: "https://github.com/anmedina-su/Java-Blackjack",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 2,
		title: "Avacado Predictive Data Analysis Project",
		modalNum: "06",
		description: "Predictive Data Analysis",
		imageURL: "images/portfolio/avacados.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-avacados.jpg",
			description: `Prediciting the average price of an avacado`,
			categories: ["Python", "pandas", "Data Analysis"],
			links: [
				{
					url: "https://github.com/anmedina-su/AvacadoPredictiveAnalysis",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 9,
		title: "Better Wordle Clone",
		modalNum: "07",
		description: "Web Development",
		imageURL: "images/portfolio/wordle.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-wordle.jpg",
			description: `Web App created with ReactJS and utilization of third party api to
            get word`,
			categories: ["Web Development", "React"],
			links: [
				{ url: "https://serene-clarke-db8292.netlify.app/", title: "Link" },
				{
					url: "https://github.com/anmedina-dev/wordle-clone",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 7,
		title: "Coding Calendar Tracker",
		modalNum: "08",
		description: "Coding Calender NextJS Web application",
		imageURL: "images/portfolio/calender.jpg",
		modalObj: {
			imageURL: "images/portfolio/calender.jpg",
			description: `Log and track what you coding and leetcode practice you've done. Web App created
            using NextJS with Typescript, NextAuth, Firebas Realtime DB.`,
			categories: ["Web Development", "React"],
			links: [
				{ url: "https://calender-web-app.vercel.app/", title: "Link" },
				{
					url: "https://github.com/anmedina-dev/Calender-Web-App",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 8,
		title: "Classroom Teacher Seating Chart",
		modalNum: "09",
		description: "NextJS Web App",
		imageURL: "images/portfolio/classroom.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-classroom.jpg",
			description: `Teachers can create their classroom with tables and classes, enter students
            accordingly and use the application to rearrange students in their respective class
            and classroom. Web App created using NextJS with Typescript, Clerk Auth, Prisma,
            PlanetScale DB.`,
			categories: ["Web Development", "NextJS"],
			links: [
				{ url: "https://seating-chart-web-app.vercel.app/", title: "Link" },
				{
					url: "https://github.com/anmedina-dev/seating-chart-web-app",
					title: "Github",
				},
			],
		},
	},
	{
		importance: 7,
		title: "Teacher Assignment Web App",
		modalNum: "10",
		description: "NextJS - T3 Stack",
		imageURL: "images/portfolio/teacher.jpg",
		modalObj: {
			imageURL: "images/portfolio/modals/m-teacher.jpg",
			description: `Teachers can upload assignments with files. Web App created using T3 Stack - NextJS
            with Typescript, NextAuth, TRPC with Prisma, Tailwind, Supabase DB, Supabase
            Storage.`,
			categories: ["Web Development", "NextJS"],
			links: [
				{ url: "https://teacher-assignment-web-app-vdgh.vercel.app/", title: "Link" },
				{
					url: "https://github.com/anmedina-dev/teacher-assignment-web-app",
					title: "Github",
				},
			],
		},
	},
];

export default works;
