import React from "react";
import rData from "../../rData";
export default function Footer() {
	return (
		<>
			<footer>
				<div class="row">
					<div class="twelve columns">
						<ul class="social-links">
							<li>
								<a href={rData["linkedin-link"]}>
									<i class="fa fa-linkedin"></i>
								</a>
							</li>
							<li>
								<a href={rData["github-link"]}>
									<i class="fa fa-github"></i>
								</a>
							</li>
						</ul>

						<ul class="copyright">
							<li>&copy; Copyright 2024 Antranik Medina</li>
						</ul>
					</div>

					<div id="go-top">
						<a class="smoothscroll" title="Back to Top" href="#home">
							<i class="icon-up-open"></i>
						</a>
					</div>
				</div>
			</footer>
		</>
	);
}
