import React from "react";
import { Fade } from "react-reveal";

export default function About() {
	return (
		<>
			<section id="about">
				<Fade right>
					<div className="row">
						<div className="three columns">
							<img
								className="profile-pic"
								src="images/me_pr.jpg"
								alt=""
							/>
						</div>

						<div className="nine columns main-col">
							<h2>About Me</h2>

							<p>
								I am a software engineer currently working
								for Express Scripts. I am a team player with
								an extensive range of skills in software
								development who learns quickly and is
								enthusiastic about new technologies. I like
								to build web applications that I believe can
								help my day to day life tasks and to refine
								my skills.
							</p>

							<div className="row">
								<div className="columns contact-details">
									<h2>Contact Details</h2>
									<p className="address">
										<span>Antranik Medina</span>
										<br />
										<span>Edgewater, NJ 07020 US</span>
										<br />
										<span>(201) 314-6377</span>
										<br />
										<span>antranikmedina@gmail.com</span>
									</p>
								</div>

								<div className="columns download">
									<p>
										<a
											href="resume/Antranik-Medina-Resume.docx"
											download="Antranik-Medina-Resume.docx"
											className="button"
										>
											<i className="fa fa-download"></i>
											Download Resume
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</Fade>
			</section>
		</>
	);
}
