import React from "react";
import "./App.css";
import rData from "./rData";
import Header from "./components/header/header";
import About from "./components/about/about";
import Resume from "./components/resume/resume";
import Portfolio from "./components/portfolio/portfolio";
import ContactUs from "./components/contactus/contactus";
import Footer from "./components/footer/footer";

function App() {
	return (
		<div className="App">
			<Header rData={rData} />
			<About />
			<Resume />
			<Portfolio />
			<ContactUs />
			<Footer rData={rData} />
		</div>
	);
}

export default App;
