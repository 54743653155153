import React, { useState } from "react";
import { Flip } from "react-reveal";
import works from "./works.js";

function PortfolioItem({ handleClick, item }) {
	return (
		<div onClick={handleClick} className="columns portfolio-item">
			<div className="item-wrap">
				<a title={`#modal-${item.modalNum}`}>
					<img alt="" src={item.imageURL} />
					<div className="overlay">
						<div className="portfolio-item-meta">
							<h5>{item.title}</h5>
							<p>{item.description}</p>
						</div>
					</div>
					<div className="link-icon">
						<i className="icon-plus"></i>
					</div>
				</a>
			</div>
		</div>
	);
}

function PortfolioModal({ item, handleClose }) {
	return (
		<div id={`modal-${item.modalNum}`} className="popup-modal">
			<img className="scale-with-grid" src={item.modalObj.imageURL} alt="" />

			<div className="description-box">
				<h4>{item.title}</h4>
				<p>{item.modalObj.description}</p>
				<span className="categories">
					<i className="fa fa-tag">{item.modalObj.categories.join(", ")}</i>
				</span>
			</div>

			<div className="link-box">
				{item.modalObj.links.map((link) => (
					<a href={link.url}>{link.title}</a>
				))}
				<a className="popup-modal-dismiss" onClick={handleClose}>
					Close
				</a>
			</div>
		</div>
	);
}

export default function Portfolio() {
	const [chosenItem, setChosenItem] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	function handleClick(item) {
		setChosenItem(item);
		setIsModalOpen(true);
	}

	function handleClose() {
		setChosenItem(null);
		setIsModalOpen(false);
	}

	const sortedWorks = works.sort((a, b) => b.importance - a.importance);

	return (
		<>
			<section id="portfolio">
				<div className="row">
					<div className="twelve columns collapsed">
						<h1>Check out my projects and their repositories!</h1>

						<div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
							<Flip right cascade>
								{sortedWorks.length &&
									works.map((work) => (
										<PortfolioItem
											key={work.modalNum}
											item={work}
											handleClick={() => handleClick(work)}
										/>
									))}
							</Flip>
							{isModalOpen && <PortfolioModal item={chosenItem} handleClose={handleClose} />}
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
