import React from "react";
import rData from "../../rData";
import { Fade } from "react-reveal";
export default function Header() {
	return (
		<Fade>
			<header id="home">
				<nav id="nav-wrap">
					<a
						className="mobile-btn"
						href="#nav-wrap"
						title="Show navigation"
					>
						Show navigation
					</a>
					<a
						className="mobile-btn"
						href="#"
						title="Hide navigation"
					>
						Hide navigation
					</a>

					<ul id="nav" className="nav">
						<li className="current">
							<a className="smoothscroll" href="#home">
								Home
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#about">
								About
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#resume">
								Resume
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#portfolio">
								Works
							</a>
						</li>
						<li>
							<a className="smoothscroll" href="#contact">
								Contact
							</a>
						</li>
					</ul>
				</nav>

				<div className="row banner">
					<div className="banner-text i-am-section">
						<h1 className="responsive-headline">
							I'm {rData.name}
						</h1>
						<h3>
							I'm a <span>{rData.role}</span> creating
							impactful and effective visual identities for
							myself and clients. Learn more
							<a className="smoothscroll" href="#about">
								about me!
							</a>
						</h3>
						<hr />
						<ul className="social">
							<li>
								<a href={rData["linkedin-link"]}>
									<i className="fa fa-linkedin"></i>
								</a>
							</li>
							<li>
								<a href={rData["github-link"]}>
									<i className="fa fa-github"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>

				<p className="scrolldown">
					<a className="smoothscroll" href="#about">
						<i className="icon-down-circle"></i>
					</a>
				</p>
			</header>
		</Fade>
	);
}
