import React, { useState } from "react";
import axios from "axios";

export default function ContactUs() {
	const [name, setName] = useState("");
	const [subject, setSubject] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const clearStates = () => {
		setName("");
		setSubject("");
		setEmail("");
		setMessage("");
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		axios
			.post("https://portfolio-server-zcpz.onrender.com/api/contact/", {
				name: name,
				email: email,
				subject: subject,
				message: message,
			})
			.then((res) => {
				console.log(res);
				console.log(res.data);
			});
		clearStates();
		document.getElementById("contactForm").reset();
		//this.state = "SUCCESS"
	};

	return (
		<>
			<section id="contact">
				<div class="row section-head">
					<div class="two columns header-col">
						<h1>
							<span>Get In Touch.</span>
						</h1>
					</div>

					<div class="ten columns">
						<p class="lead">
							Currently looking for free lance opportunities, so if you would like to discuss
							working on a project with me feel free to reach me!
						</p>
					</div>
				</div>

				<div class="row">
					<div class="eight columns">
						<form onSubmit={handleSubmit} id="contactForm" name="contactForm">
							<fieldset>
								<div>
									<label htmlFor="name">
										Name <span class="required">*</span>
									</label>
									<input
										type="text"
										size="35"
										id="name"
										name="name"
										onChange={(event) => setName(event.target.value)}
									/>
								</div>

								<div>
									<label htmlFor="email">
										Email <span class="required">*</span>
									</label>
									<input
										type="text"
										size="35"
										id="email"
										name="email"
										onChange={(event) => setEmail(event.target.value)}
									/>
								</div>

								<div>
									<label htmlFor="subject">
										Subject<span class="required">*</span>
									</label>
									<input
										type="text"
										size="35"
										id="subject"
										name="subject"
										onChange={(event) => setSubject(event.target.value)}
									/>
								</div>

								<div>
									<label htmlFor="message">
										Message <span class="required">*</span>
									</label>
									<textarea
										as="text"
										cols="50"
										rows="15"
										name="message"
										onChange={(event) => setMessage(event.target.value)}
									/>
								</div>

								<div>
									<input class="submit" type="Submit" value="Submit" />
								</div>
							</fieldset>
						</form>
					</div>

					<aside class="four columns footer-widgets">
						<div class="widget widget_contact">
							<h4>Address and Phone</h4>
							<p class="address">
								Antranik Medina
								<br />
								Edgewater, NJ 07020 US
								<br />
								<span>(201) 314-6377</span>
								<br />
								antranikmedina@gmail.com
							</p>
						</div>
					</aside>
				</div>
			</section>
		</>
	);
}
